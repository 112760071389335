body {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	color: #666;
	font-size: 12px;
	line-height: 20px;
	width: 100%;
}

h1, h2, h3, h4, h5, h6 {
	color: #444;
}

/* default font size */
.fa {
	font-size: 14px;
}

/* Override the bootstrap defaults */
h1 {
	font-size: 33px;
}

h2 {
	font-size: 27px;
}

h3 {
	font-size: 21px;
}

h4 {
	font-size: 15px;
}

h5 {
	font-size: 12px;
}

h6 {
	font-size: 10.2px;
}

a {
	color: #060102;
	&:hover{
		color: #fe3266;
	}
}

a:hover {
	text-decoration: none;
}

legend {
	font-size: 18px;
	padding: 7px 0px
}

label {
	font-size: 12px;
	font-weight: normal;
}

select.form-control, textarea.form-control, input[type="text"].form-control, input[type="password"].form-control, input[type="datetime"].form-control, input[type="datetime-local"].form-control, input[type="date"].form-control, input[type="month"].form-control, input[type="time"].form-control, input[type="week"].form-control, input[type="number"].form-control, input[type="email"].form-control, input[type="url"].form-control, input[type="search"].form-control, input[type="tel"].form-control, input[type="color"].form-control {
	font-size: 12px;
}

.input-group input, .input-group select, .input-group .dropdown-menu, .input-group .popover {
	font-size: 12px;
}

.input-group .input-group-addon {
	font-size: 12px;
	height: 30px;
}

/* Fix some bootstrap issues */
span.hidden-xs, span.hidden-sm, span.hidden-md, span.hidden-lg {
	display: inline;
}

.nav-tabs {
	margin-bottom: 15px;
}

div.required .control-label:before {
	content: '* ';
	color: #F00;
	font-weight: bold;
}

/* Gradent to all drop down menus */
.dropdown-menu li > a:hover {
	text-decoration: none;
	color: #ffffff;
	background-color: #f3f3f3;
}

/* top */
#top {
	background-color: #EEEEEE;
	border-bottom: 1px solid #e2e2e2;
	padding: 4px 0px 3px 0;
	margin: 0 0 20px 0;
	min-height: 40px;
}

#top .container {
	padding: 0 20px;
}

#top #form-currency .currency-select,
#top #form-language .language-select {
	text-align: left;
}

#top #form-currency .currency-select:hover,
#top #form-language .language-select:hover {
	text-shadow: none;
	color: #ffffff;
	background-color: #a559a5;
	background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
	background-repeat: repeat-x;
}

#top .btn-link, #top-links li, #top-links a {
	color: #888;
	text-shadow: 0 1px 0 #FFF;
	text-decoration: none;
}

#top .btn-link:hover, #top-links a:hover {
	color: #444;
}

#top-links .dropdown-menu a {
	text-shadow: none;
}

#top-links .dropdown-menu a:hover {
	color: #FFF;
}

#top .btn-link strong {
	font-size: 14px;
	line-height: 14px;
}

#top-links {
	padding-top: 6px;
}

#top-links a + a {
	margin-left: 15px;
}

/* logo */
#logo {
	margin: 0 0 10px 0;
}

#logo h1 {
	font-size: 28px;
	margin-bottom: 20px;
	margin-top: -5px;
}

/* search */
#search {
	margin-bottom: 10px;
}

#search .input-lg {
	height: 42px;
	line-height: 20px;
	padding: 0 10px;
}

#search .btn-lg {
	font-size: 15px;
	line-height: 18px;
	padding: 10px 35px;
	text-shadow: 0 1px 0 #FFF;
}

/* cart */

#cart > .btn {
	font-size: 12px;
	line-height: 18px;
}

#cart.open > .btn {
	background-image: none;
	background-color: #FFFFFF;
	border: 1px solid #E6E6E6;
	color: #666;
	box-shadow: none;
	text-shadow: none;
}

#cart.open > .btn:hover {
	color: #444;
}

#cart .dropdown-menu {
	background: #eee;
	z-index: 1001;
}

#cart .dropdown-menu {
	min-width: 100%;
}

@media (max-width: 478px) {
	#cart .dropdown-menu {
		width: 100%;
	}
}

#cart .dropdown-menu table {
	margin-bottom: 10px;
}

#cart .dropdown-menu li > div {
	min-width: 427px;
	padding: 0 10px;
}

@media (max-width: 478px) {
	#cart .dropdown-menu li > div {
		min-width: 100%;
	}
}

#cart .dropdown-menu li p {
	margin: 20px 0;
}

/* menu */
#menu {

}

#menu .nav > li > a {
	color: #060102;
	padding: 18px 15px 18px 15px;
	min-height: 15px;
	background-color: transparent;
	font-size: 17px;
}

#menu .nav > li > a:hover, #menu .nav > li.open > a {
	background-color: rgba(0, 0, 0, 0.1);
}

#menu .dropdown-menu {
	padding-bottom: 0;
}

#menu .dropdown-inner {
	display: table;
}

#menu .dropdown-inner ul {
	display: table-cell;
}

#menu .dropdown-inner a {
	min-width: 160px;
	display: block;
	padding: 3px 20px;
	clear: both;
	line-height: 20px;
	color: #333333;
	font-size: 12px;
}

#menu .dropdown-inner li a:hover {
	color: #fe3266 !important;
}

#menu li a:hover {
	color: #fe3266 !important;
}

#menu .see-all {
	display: block;
	margin-top: 0.5em;
	border-top: 1px solid #DDD;
	padding: 3px 20px;
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 3px 3px;
	font-size: 12px;
}

#menu .see-all:hover, #menu .see-all:focus {
	text-decoration: none;
	color: #ffffff;
	background-color: #a559a5;
	background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
	background-repeat: repeat-x;
}

#menu #category {
	float: left;
	padding-left: 15px;
	font-size: 16px;
	font-weight: 700;
	line-height: 40px;
	color: #fff;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}

#menu .btn-navbar {
	font-size: 15px;
	font-stretch: expanded;
	color: #FFF;
	padding: 2px 18px;
	float: right;
	background-color: #000000;
	background-repeat: repeat-x;
}

#menu .btn-navbar:hover, #menu .btn-navbar:focus, #menu .btn-navbar:active, #menu .btn-navbar.disabled, #menu .btn-navbar[disabled] {
	color: #ffffff;
	background-color: #000000;
}

@media (min-width: 768px) {
	#menu .dropdown:hover .dropdown-menu {
		display: block;
	}
}

@media (max-width: 767px) {

	#menu #category {
		text-shadow: none;
		color: #000;
		font-weight: 300;
	}

	#menu .nav > li:first-child .dropdown-toggle:before {
		content: none !important;
	}

	#menu .nav > li:first-child .dropdown-toggle {
		padding-left: 15px !important;
	}


	#menu {
		border-radius: 4px;
	}

	#menu div.dropdown-inner > ul.list-unstyled {
		display: block;
	}

	#menu div.dropdown-menu {
		margin-left: 0 !important;
		padding-bottom: 10px;
		background-color: rgba(0, 0, 0, 0.1);
	}

	#menu .dropdown-inner {
		display: block;
	}

	#menu .dropdown-inner a {
		width: 100%;
		color: #fff;
	}

	#menu .dropdown-menu a:hover,
	#menu .dropdown-menu ul li a:hover {
		background: rgba(0, 0, 0, 0.1);
	}

	#menu .see-all {
		margin-top: 0;
		border: none;
		border-radius: 0;
		color: #fff;
	}
}


/* content */
#content {
	min-height: 600px;
}

/* footer */
footer {
	background-color: #1b1b1b;
	border-top: 1px solid #ddd;
	color: #e2e2e2;
	font-family: Myriad Pro;
}

footer hr {
	border-top: none;
	border-bottom: 1px solid #666;
}

footer a {
	color: #ffffff;
}

footer a:hover {
	color: #fff;
}

footer h5 {
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	font-weight: bold;
	color: #fff;
}

/* alert */
.alert {
	padding: 8px 14px 8px 14px;
}

/* breadcrumb */
.breadcrumb {
	margin: 0 0 20px 0;
	padding: 18px 0 8px 0;

}

.breadcrumb i {
	font-size: 15px;
}

.breadcrumb > li {
	text-shadow: 0 1px 0 #FFF;
	padding: 0 20px;
	position: relative;
	white-space: nowrap;
}

.breadcrumb > li + li:before {
	content: '';
	padding: 0;
}

.breadcrumb > li:after {
	content: '';
	display: block;
	position: absolute;
	top: 4px;
	right: -7px;
	width: 7px;
	height: 13px;
	background-image: url(/image/arrowright.png);
	background-size: 100%;
	opacity: 0.2;
}

.breadcrumb > li:last-child:after {
	content: none;
}

.pagination {
	margin: 12px 0 0 0px;
}

/* buttons */
.buttons {
	margin: 1em 0;
}

.btn {
	padding: 7.5px 12px;
	font-size: 12px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
}

.btn-xs {
	font-size: 9px;
}

.btn-sm {
	font-size: 10.2px;
}

.btn-lg {
	padding: 10px 16px;
	font-size: 15px;
}

.btn-group > .btn, .btn-group > .dropdown-menu, .btn-group > .popover {
	font-size: 12px;
}

.btn-group > .btn-xs {
	font-size: 9px;
}

.btn-group > .btn-sm {
	font-size: 10.2px;
}

.btn-group > .btn-lg {
	font-size: 15px;
}

.btn-default {
	color: #777;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	background-color: #e7e7e7;
	background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
	background-repeat: repeat-x;
	border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
}

.btn-primary {
	color: #ffffff;
	background-color: #1b1b1b;
	border-radius: 0;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
	background-color: #0d0d0d;
	background-position: 0 -15px;
}

.btn-warning {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #faa732;
	background-image: linear-gradient(to bottom, #fbb450, #f89406);
	background-repeat: repeat-x;
	border-color: #f89406 #f89406 #ad6704;
}

.btn-warning:hover, .btn-warning:active, .btn-warning.active, .btn-warning.disabled, .btn-warning[disabled] {
	box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}

.btn-danger {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #da4f49;
	background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
	background-repeat: repeat-x;
	border-color: #bd362f #bd362f #802420;
}

.btn-danger:hover, .btn-danger:active, .btn-danger.active, .btn-danger.disabled, .btn-danger[disabled] {
	box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}

.btn-success {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #5bb75b;
	background-image: linear-gradient(to bottom, #62c462, #51a351);
	background-repeat: repeat-x;
	border-color: #51a351 #51a351 #387038;
}

.btn-success:hover, .btn-success:active, .btn-success.active, .btn-success.disabled, .btn-success[disabled] {
	box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}

.btn-info {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #df5c39;
	background-image: linear-gradient(to bottom, #e06342, #dc512c);
	background-repeat: repeat-x;
	border-color: #dc512c #dc512c #a2371a;
}

.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
	background-image: none;
	background-color: #df5c39;
}

.btn-link {
	border-color: rgba(0, 0, 0, 0);
	cursor: pointer;
	color: #23A1D1;
	border-radius: 0;
}

.btn-link, .btn-link:active, .btn-link[disabled] {
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	box-shadow: none;
}

.btn-inverse {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #363636;
	background-image: linear-gradient(to bottom, #444444, #222222);
	background-repeat: repeat-x;
	border-color: #222222 #222222 #000000;
}

.btn-inverse:hover, .btn-inverse:active, .btn-inverse.active, .btn-inverse.disabled, .btn-inverse[disabled] {
	background-color: #222222;
	background-image: linear-gradient(to bottom, #333333, #111111);
}

#button-cart{
	transition: .8s;
	background: $primary-color;
	&:hover{
		background: lighten($primary-color, 30%);
	}
}

/* list group */
.list-group a {
	color: #888888;
	padding: 8px 12px;
	font-family: Myriad Pro;
	font-size: 16px;
	color: #000000;
	font-weight: 400;
}

.list-group a.active, .list-group a.active:hover, .list-group a:hover {
	color: #444444;
	background: #eeeeee;
	border: 1px solid #DDDDDD;
	text-shadow: 0 1px 0 #FFF;
}

/* carousel */
.carousel-caption {
	color: #FFFFFF;
	text-shadow: 0 1px 0 #000000;
}

.carousel-control .icon-prev:before {
	content: '\f053';
	font-family: FontAwesome;
}

.carousel-control .icon-next:before {
	content: '\f054';
	font-family: FontAwesome;
}

/* product list */
.product-thumb {
	margin-bottom: 20px;
	overflow: auto;
	font-family: Myriad Pro;
}

.product-thumb .image {
	text-align: center;
}

.product-thumb .image a {
	display: block;
}

.product-thumb .image a:hover {
	opacity: 0.8;
}

.product-thumb .image img {
	margin-left: auto;
	margin-right: auto;
	padding: 5px 0;
}

@media (min-width: 767px) {
	.product-list .product-thumb .image {
		float: left;
		padding: 0 15px;
	}
}

.product-thumb h4 {
	font-weight: bold;
}

.product-thumb .caption {
	min-height: 110px;
}

.product-list .product-thumb .caption {
	margin-left: 230px;
}

@media (max-width: 1200px) {
	.product-grid .product-thumb .caption {
		min-height: 210px;
		padding: 0 10px;
	}
}

@media (max-width: 767px) {
	.product-list .product-thumb .caption {
		min-height: 0;
		margin-left: 0;
		padding: 0 10px;
	}

	.product-grid .product-thumb .caption {
		min-height: 0;
	}
}

.product-thumb .rating {
	padding-bottom: 10px;
}

.rating .fa-stack {
	font-size: 8px;
}

.rating .fa-star-o {
	color: #999;
	font-size: 15px;
}

.rating .fa-star {
	color: #FC0;
	font-size: 15px;
}

.rating .fa-star + .fa-star-o {
	color: #E69500;
}

h2.price {
	margin: 0;
}

.product-thumb .price {
	color: #444;
}

.product-thumb .price-new {
	font-weight: 600;
}

.product-thumb .price-old {
	color: #737373;
	text-decoration: line-through;
	margin-left: 10px;
}

.product-thumb .price-tax {
	color: #999;
	font-size: 12px;
	display: block;
}

.product-list .product-thumb .button-group {
	border-left: 1px solid #ddd;
}

@media (max-width: 768px) {
	.product-list .product-thumb .button-group {
		border-left: none;
	}
}

.product-thumb .button-group button {
	width: 60%;
	border: none;
	display: inline-block;
	float: left;
	background-color: #ffffff;
	border: 2px solid #1b1b1b;
	color: #1b1b1b;
	line-height: 38px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	transition: all 0.2s;
}

.product-thumb .button-group button + button {
	width: 20%;
	border-left: 1px solid #ddd;
}

.product-thumb .button-group button:hover {
	background-color: #efefef;
	text-decoration: none;
	cursor: pointer;
}

@media (max-width: 1200px) {
	.product-thumb .button-group button, .product-thumb .button-group button + button {
		width: 33.33%;
	}
}

@media (max-width: 767px) {
	.product-thumb .button-group button, .product-thumb .button-group button + button {
		width: 33.33%;
	}
}

.thumbnails {
	overflow: auto;
	clear: both;
	list-style: none;
	padding: 0;
	margin: 0;
}

.thumbnails > li {
	margin-left: 20px;
}

.thumbnails {
	margin-left: -20px;
}

.thumbnails > img {
	width: 100%;
}

.image-additional a {
	margin-bottom: 20px;
	padding: 5px;
	display: block;
	border: 1px solid #ddd;
}

.thumbnails .image-additional {

}


.additional-pro {
	cursor: pointer;
	width: 33.33%;
	margin-right: 4%;
}

.additional-pro:last-child {
	margin-right: 0;
}


@media (min-width: 1200px) {
	#content .col-lg-2:nth-child(6n+1),
	#content .col-lg-3:nth-child(4n+1),
	#content .col-lg-4:nth-child(3n+1),
	#content .col-lg-6:nth-child(2n+1) {
		clear: left;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	#content .col-md-2:nth-child(6n+1),
	#content .col-md-3:nth-child(4n+1),
	#content .col-md-4:nth-child(3n+1),
	#content .col-md-6:nth-child(2n+1) {
		clear: left;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	#content .col-sm-2:nth-child(6n+1),
	#content .col-sm-3:nth-child(4n+1),
	#content .col-sm-4:nth-child(3n+1),
	#content .col-sm-6:nth-child(2n+1) {
		clear: left;
	}
}

/* fixed colum left + content + right*/
@media (min-width: 768px) {
	#column-left .product-layout .col-md-3 {
		width: 100%;
	}

	#column-left + #content .product-layout .col-md-3 {
		width: 50%;
	}

	#column-left + #content + #column-right .product-layout .col-md-3 {
		width: 100%;
	}

	#content + #column-right .product-layout .col-md-3 {
		width: 100%;
	}
}

/* fixed product layouts used in left and right columns */
#column-left .product-layout, #column-right .product-layout {
	width: 100%;
}

/* fixed mobile cart quantity input */
.input-group .form-control[name^=quantity] {
	min-width: 50px;
}

/* Missing focus and border color to overwrite bootstrap */
.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
	background-image: none;
	background-color: #df5c39;
}


.size-table td {
	border: 1px solid #eee;
}

.exitblock {
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 100000;
	background: #000000b8;
}

.exitblock .fon {
	
	opacity: .8;
	position: fixed;
	width: 100%;
	height: 100%;
}

.exitblock .modaltext {
	box-sizing: border-box;
	padding: 20px 40px;
	border: 2px solid #AEAEAE;
	background: url(/image/pop.png) no-repeat;
	margin: 120px auto;
	top: 80px;
	left: 25%;
	position: sticky;
	z-index: 10002;
	width: 50%;
	max-width: 580px;
	min-width: 400px;
	height: 530px;
	box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.5);
}

.text-modal {
	margin-top: 240px;
	text-align: center;
}

.text-modal h2 {
	
	font-size: 44px;
}

.text-modal p {
	margin-top: 30px;
	font-size: 26px;
	line-height: 34px;
}

.closeblock {
	cursor: pointer;
	position: fixed;
	line-height: 60px;
	font-size: 82px;
	transform: rotate(45deg);
	text-align: center;
	top: 20px;
	right: 30px;
	color: #337AB7;
}

.closeblock:hover {
	color: #000;
}
