/*@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');*/
@import "../../../MyriadPro/stylesheet.css";
$root-font-size: 14px;

$primary-color: #fe3266;

$global-primary-background: #060102 !default;
$global-success-background: #32d296 !default;
$global-warning-background: #faa05a !default;
$global-danger-background: #f0506e !default;
