/*@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');*/

html, body {
	overflow-x: hidden;
	/*font-family: 'Myriad Pro';*/
	font-size: 17px;
}


.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
	position: relative;
	min-height: 1px;
	padding-right: 10px;
	padding-left: 10px;
}

.col-xs-1-5 {
	width: 20%;
	float: left;
}

span.cat-count-engage {
	float: right;
}


.review-block {
	border: 1px solid silver;
	margin-bottom: 20px;
	padding: 20px;
}

.review-block p:first-child {
	font-weight: bold;
}

@media (min-width: 768px) {
	.col-sm-1-5 {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-1-5 {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-1-5 {
		width: 20%;
		float: left;
	}
}

.dv-ursale span:last-child {
	color: #fe3266 !important;
	
}

.price-new {
	color: #fe3266;
}

.price {
	color: #fe3266 !important;
	
}

.dv-header-top a[href="https://ok.ru/nrasskazova"] {
	width: 28px;
	height: 28px;
	background-image: url(/image/header/ok.png);
	display: inline-block;
	vertical-align: top;
}

.dv-header-top a[href="https://vk.com/hope_clothing68"] {
	width: 28px;
	height: 28px;
	background-image: url(/image/header/vk.png);
	display: inline-block;
	vertical-align: top;
}

.dv-header-top a[href="https://www.instagram.com/odezhda_nadezhda.rsk/"] {
	width: 28px;
	height: 28px;
	background-image: url(/image/header/instagram.png);
	display: inline-block;
	vertical-align: top;
}

.dv-header-middle a[href="/wishlist/"] {
	width: 32px;
	height: 32px;
	background-image: url(/image/header/like.png);
	display: inline-block;
	vertical-align: top;
}

.dv-header-middle a[href="/compare-products/"] {
	width: 29px;
	height: 29px;
	background-image: url(/image/header/exchange.png);
	display: inline-block;
	vertical-align: top;
}


.dv-header-middle a[href="/wishlist/"]:hover {
	background-image: url(/image/header/like2.png);
}

.dv-header-middle a[href="/compare-products/"]:hover {
	background-image: url(/image/header/exchange2.png);
}


.d-main-menu-container {
	background: #f7f7f7;
	position: relative;
}

.navbar-pro li.dropdown {
	position: static;
}

#menu.navbar {
	position: static;
}


.swiper-slide {
	position: relative;
}

.link-engager {
	position: absolute;
	left: 5.5%;
	bottom: 15%;
	color: #2a2927;
	background: white;
	width: 200px;
	height: 38px;
	line-height: 38px;
	font-size: 20px;
	border-radius: 4px;
	-webkit-box-shadow: 0px 1px 4px 0px rgba(87, 86, 87, 0.35);
	-moz-box-shadow: 0px 1px 4px 0px rgba(87, 86, 87, 0.35);
	box-shadow: 0px 1px 4px 0px rgba(87, 86, 87, 0.35);
	cursor: pointer;
	transition: all 0.2s;
}

.link-engager:hover {
	box-shadow: none;
	background: #3a3a3a;
	color: white;
}

.feature-title-pro, .latest-title-pro {
	font-weight: bold;
	margin: 25px 0 25px 0;
	font-size: 24px;
}

.latest-title-pro {
	display: none;
}

.bold {
	font-weight: bold;
	color: #444;
}

.b, .strong{
	font-weight: bold;
}

.i, .em{
	font-style: italic;
}

.buy-button {
	width: 70%;
	background: white;
	color: #2a2927;
	border: 2px solid #2a2927;
	height: 48px;
	font-size: 16px;
	display: inline-block;
	vertical-align: top;
}


.one-click-button {
	transition: .8s;
	width: 70%;
	border: 0;
	height: 48px;
	font-size: 17px;
	cursor: pointer;
	text-align: center;
	line-height: 44px;
	display: block;
	vertical-align: top;
	margin-top: 14px;
	background: #2a2927;
	color: white;
	&:hover{
		background: #767671;
	}
}


.buy-container {
	font-size: 0;
}


.search-container {
	display: none;
}


.quantity-container {
	display: inline-block;
	vertical-align: top;
	width: 50%;
	margin-bottom: 10px;
}

.quantity-container > * {
	display: inline-block;
	user-select: none;
}


.quantity-button {
	background: #f7f7f7;
	width: 46px;
	height: 46px;
	text-align: center;
	line-height: 46px;
	color: #444;
	font-size: 16px;
	cursor: pointer;
}


.title-hider {
	font-size: 28px;
	margin: 0 0 20px 0;
	font-family: 'Times New Roman';
	line-height: initial;
	color: #2a2927;
	display: none;
}


button.btn.btn-default.btn-compare {
	width: 29px;
	height: 29px;
	display: block;
	background-image: url(/image/header/exchange.png);
	border: 0;
	border-radius: 0;
	background-color: transparent;
}


button.btn.btn-default.btn-heart {
	width: 32px;
	height: 32px;
	display: block;
	background-image: url(/image/header/like.png);
	border: 0;
	border-radius: 0;
	background-color: transparent;
}


.btn-pro-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.quantity-container input[name="quantity"] {
	vertical-align: top;
	width: 46px;
	border-radius: 0;
	text-align: center;
	border: 0;
	background-color: #e7e7e7;
	height: 46px;
}

.quantity-label {
	display: inline-block;
	height: 46px;
	margin: 0;
	padding: 0;
	line-height: 46px;
	margin-right: 6px;
	font-weight: 500;
	color: #444;
	font-size: 14px;
	text-shadow: none;
}


.dv-stock {
	position: relative;
	padding-left: 25px;
	font-size: 13px;
}

.dv-stock:before {
	content: " ";
	position: absolute;
	left: -3px;
	top: 1px;
	width: 18px;
	height: 18px;
	background-image: url(/image/instock.png);
}


.quantity-button-minus {
	border-radius: 4px 0 0 4px;
}

.quantity-button-plus {
	border-radius: 0 4px 4px 0;
}


.dropdown-menu.inner-custom-menu {
	background-color: #ffffff;
	width: 100%;
	padding-bottom: 0 !important;
}

#menu .inner-custom-menu .dropdown-inner a {
	color: #000000;
	font-size: 16px;
	padding: 6px 20px;
	font-family: 'Myriad Pro';
	font-weight: 400;
}

#menu .inner-custom-menu .dropdown-inner {
	width: 100%;
	padding: 26px 0;
}

.additional-pro-container {
	margin-left: 20px;
	display: flex;
	justify-content: start;
}

#menu .dropdown-inner ul li {
	position: relative;
}

#menu .dropdown-inner ul li ul {
	display: none;
}

#menu .dropdown-inner ul li:hover ul {
	display: block;
	position: absolute;
	list-style: none;
	top: 0px;
	right: -90%;
	width: 90%;
	padding: 0;
	margin: 0;
	background: #303030;
	border-left: 1px dashed #757171;
}

.menu_with_arrow:after {
	content: " ";
	width: 16px;
	height: 16px;
	right: 4px;
	top: calc(50% - 8px);
	position: absolute;
	background-image: url(/image/arrow-right.png);
	background-size: 100%;
}

.btn-heart, .btn-compare {
	background: #e877e8;
	color: white;
	width: 50%;
}


.oldprice {
	font-size: 26px;
}

.newprice {
	color: #fe3266;
	font-size: 32px;
	font-weight: bold;
	margin-left: 10px;
}

.price-container {
	margin: 15px 0;
	position: relative;
}


#menu .nav > li:first-child .dropdown-toggle {
	position: relative;
	padding: 18px 25px 18px 55px;
}

#menu .nav > li:first-child .dropdown-toggle:before {
	content: " ";
	position: absolute;
	width: 32px;
	height: 22px;
	left: 10px;
	top: calc(50% - 11px);
	position: absolute;
	background-image: url(/image/header/menu.png);
	background-size: 100%;
}

.btn-navbar.navbar-toggle {
	margin-right: 0;
	margin-top: 10px;
}

.navbar-pro .background-engage-menu {
	box-shadow: none;
	display: block;
	width: 100%;
	position: static;
	margin-bottom: 15px;
	background: #8a478a;
	padding-bottom: 0;
}

#menu .background-engage-menu .dropdown-inner li {
	border-bottom: 1px dashed #d4d4d4;
}

#menu .background-engage-menu .dropdown-inner li:last-child {
	border-bottom: 0 !important;
}

.navbar-nav {
	margin-top: 0;
}

.btn-new-pro {
	background-color: #a559a5;
	background-image: none;
	border: 0;
}

.breadcrumb a {
	color: #666;
}

.product-thumb .price-new {
	font-weight: 600;
	font-size: 22px;
}

.product-thumb .price {
	font-size: 24px;
	font-weight: bold;
	margin-top: 10px;
}

.d-cart-container .fa-shopping-cart {
	font-size: 24px;
}

#cart button {
	background-color: white;
	box-shadow: none;
	border: 0;
}

#cart button span {
	font-size: 16px;
}

#cart .text-right strong {
	color: #444;
}

.engage-success {
	font-size: 18px;
	margin-top: 25px;
}

.success-inner {
	width: 100%;
	display: inline-block;
	vertical-align: top;
	font-size: 14px;
	box-shadow: 0 0 3px 3px rgba(221, 221, 221, 1);
	padding: 20px;
}

.success-inner h2 {
	margin: 10px 0 25px 0;
}

.redbold {
	font-weight: bold;
	color: #ff2b2b;
	font-size: 17px;
}

.shipping-price {
	display: none;
}


.search-top-button {
	float: right;
	background-image: url(/image/searchicon.png);
	background-size: 100%;
	color: white;
	width: 42px;
	height: 42px;
	margin-top: 7px;
	cursor: pointer;
	position: relative;
	border-radius: 4px;
}

.search-top-button .search-inner-absolute {
	position: absolute;
	right: 42px;
	top: 0;


}


#common-home .alert {
	margin-top: 20px;
}

.search-move-top button {
	display: none;
}

.search-move-top {
	width: 0px;
	display: none;
}

.search-move-top #search {
	width: 100%;
}

.search-move-top input[name="search"] {
	width: 100%;
	outline: 0;
	border: 0;
	box-shadow: none;
}

.search-move-top .input-group-btn {
	display: none;
}


#compare-total {
	background: #000000;
	color: white;
	box-sizing: border-box;
	padding: 7px 22px 7px 35px;
	position: relative;
}

#compare-total:before {
	content: " ";
	width: 18px;
	height: 18px;
	position: absolute;
	top: 7px;
	left: 9px;
	background-image: url(/image/comparel.png);
}

#compare-total:hover {
	text-decoration: none;
}

#input-sort, #input-limit {
	border: 2px solid #000;
}

.row.products-pro {
	margin-top: 28px;
}

#product-category h1 {
	margin-top: 0;
	margin-bottom: 20px;
}


.engage-cat-list li:first-child {
	padding-left: 0;
}

.engage-cat-list {
	list-style: none;
	padding: 0;
	margin: 10px 0 30px 0;
}

ul.engage-cat-list li {
	display: inline-block;
	padding: 10px 14px;
}

ul.engage-cat-list li a {
	color: black;
	padding-bottom: 3px;
	border-bottom: 2px dashed black;
}

#product-product h1 {
	font-size: 28px;
	margin: 0 0 20px 15px;
	font-family: 'Myriad Pro';
	font-weight: bold;
}

.size-select {
	width: 49%;
}

.nav-tabs a {
	color: #444;
}


button:focus {
	background-color: none !important;
	outline: none !important;
}

.d-header-mobile-container {
	display: none;
}

.d-header-mobile-container .d-header-phones:before {
	content: none;
}

.d-header-mobile-container .d-header-phones {
	margin-top: 28px;
}

.d-header-mobile-container .d-header-phones a {
	font-size: 26px;
	color: #444;
	margin: 0 10px;
}


.pagination-container {
	margin-bottom: 22px;
	text-align: center;
}

.text-right-pro {
	margin-top: 20px;
	margin-bottom: 6px;
}

.sliser-pro {
	clear: both;
	width: 100%;
	height: 1px;
	background: gainsboro;
	margin: 10px 0;
}

.boldlink {
	font-weight: bold;
}

.h3 {
	color: #444;
	font-weight: 22px;
}

.h2 {
	font-size: 27px;
	color: #444;
}


.tooltip-main {
	display: none;
}

.filter-inputs-container {
	font-size: 0;
}

.filter-inputs-container > input {
	width: 40%;
	border: 2px solid silver;
	font-size: 14px;
	text-align: center;
	padding: 6px 0;
	border-radius: 5px;
	background-color: white;

}

.filter-inputs-container > div {
	font-size: 14px;
	width: 20%;
	display: inline-block;
	text-align: center;
}

.filter-inputs-container > *:last-child {
	float: right;
}

.filter-inputs-container {
	margin-bottom: 10px;
}

.attribute-pro {
	margin-top: 6px;
}

.attribute-pro p {
	margin-bottom: 2px;
}

.attr-zag {
	font-weight: bold;
}

.stock-block {
	margin-bottom: 10px;
	margin-top: 4px;
}


.button-filter-more {
	background: #1b1b1b;
	color: white !important;
	margin: 5px 0 0 0;
	font-size: 12px;
	display: inline-block;
}

.button-filter-more:hover {
	background: #ffffff !important;
	color: #444 !important;
}

.product-thumb .stock-block img {
	display: inline-block;
}

.additional-pro {
	cursor: pointer;
}

.additional-pro > div {
	border-radius: 0;
	border: 3px solid #e7e7e7;

}

.thumbnail-pro {
	border: 0;
}


.article-block {
	padding: 10px 15px;
	box-shadow: 0 0 3px 1px rgba(221, 221, 221, 1);
	margin-bottom: 15px;
}

.article-title {
	font-weight: bold;
	font-size: 18px;
	margin: 0px 0 10px 0;
}

/*.quickcheckout-cart tr:nth-child(2){display:none;}
.quickcheckout-cart tr:nth-child(3){display:none;}

*/

.clone-input-engager {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}


.price-popup-container {
	display: none;
	position: absolute;
	top: 30px;
	left: 0;
}

.list-unstyled.price-container:hover .price-popup-container {
	display: block;
	background: white;
	box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
	width: 283px;
	padding: 12px 15px;
}

.dv-ursale {
	font-weight: bold;
	margin-bottom: 6px;
}

.dv-ursale span:last-child {
	float: right;
	color: #d66dd5;
	font-weight: bold;
}

.dv-percent-sale span:last-child {
	float: right;
	color: #fe3568;
}

.dv-engage-sale {
	display: inline-block;
	background: #0d0d0d;
	color: white;
	font-size: 14px;
	padding: 2px 6px;
	margin-left: 6px;
	vertical-align: top;
	font-weight: normal;
}


/* Стили шапки редизайн */

.dv-footer-cols {
	display: flex;
	justify-content: space-between;
	padding: 45px 0;
	color: white;
}

.dv-footer-copy {
	background-color: #0d0d0d;
	padding: 14px 0px;
	font-size: 15px;
	color: #cecece;
}

.dv-footer-menu {
	padding: 0;
	list-style: none;
}


.dv-menu-title {
	font-size: 20px;
	margin-bottom: 38px;
	border-top: 2px solid white;
	padding-top: 12px;
	width: 65%;
}

.dv-footer-cols .dv-menu-col {
	width: 20%;
}

.dv-footer-cols .dv-contact-col {
	width: 40%;
	background: #0d0d0d;
	padding: 45px 35px;
}

.dv-footer-phone a {
	font-size: 20px;
	color: white;
	
}

.dv-footer-phone a:last-child {
	float: right;
}


.dv-footer-mail {
	font-size: 16px;
	margin-bottom: 30px;
	display: block;
	&:hover{
		text-decoration: underline;
	}
}

.soc-icons a {
	margin-right: 40px;
}

ul.dv-footer-menu li {
	margin-bottom: 15px;
}

.dv-footer-menu li a {
	font-size: 15px;
	color: #e2e2e2;
	&:hover{
		text-decoration: underline;
	}
}


.about-engage-wrapper {
	background-color: #f7f7f7;
	padding: 76px 0;
	margin-top: 40px;
}

.dv-footer-about-title {
	font-size: 41px;
	color: #1b1617;
	line-height: 45px;
	font-family: 'Times New Roman';
	margin: 20px 0 40px 0;
	text-transform: uppercase;
}

.dv-footer-about {
	display: flex;
	justify-content: space-between;
	padding-bottom: 60px;
	border-bottom: 1px solid black;
	margin-bottom: 40px;
}

.dv-footer-about-image {
	width: 351px;
}

.dv-footer-about-text {
	width: calc(100% - 351px);
	padding-left: 60px;
}


.dv-extra-title {
	font-size: 24px;
	font-weight: bold;
	font-family: 'Times New Roman';
	color: #1b1617;
	margin-bottom: 23px;
}

.dv-footer-extra-about a, .dv-footer-extra-about span {
	font-size: 18px;
	color: #1b1617;
	margin-right: 50px;
	font-family: 'Times New Roman';
}

.dv-footer-about-image img {
	width: 100%;
}


.dv-header-top .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dv-header-container {
	font-family: 'Myriad Pro';
}


.dv-header-top .container > div {
	width: 33.33%;
	font-weight: 600;
}


.dv-header-middle .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dv-header-middle .container > div {
	width: 33.33%;
}


.dv-header-phones a {
	font-size: 20px;
	color: #060102;
}

.dv-header-phones a:last-child {
	float: right;
}


.dv-header-logo {
	text-align: center;
}


.dv-callme-header-button {
	float: right;
	font-size: 16px;
	border-bottom: 2px dashed #444;
	padding-bottom: 2px;
	position: relative;
	top: 1px;
	cursor: pointer;
	color: #060102;
}

.dv-callme-header-button:before {
	content: " ";
	position: absolute;
	left: -38px;
	top: -2px;
	width: 28px;
	height: 28px;
	background-image: url(/image/phonecall.png);
	cursor: pointer;
}


.dv-callme-header-button:hover {
	color: #fe3266;
	border-bottom: 2px dashed #fe3266;
}

.dv-callme-header-button:hover:before {
	background-image: url(/image/phonecall-active.png);
}


.dv-callme-button {
	@include transitions();
	font-size: 16px;
	border: 2px solid white;
	width: 210px;
	text-align: center;
	line-height: 48px;
	margin: 20px 0;
	padding: 0;
	box-sizing: border-box;
	cursor: pointer;
	color: #e2e2e2;
	&:hover{
		background: #e2e2e2;
		color: #1b1b1b;
	}
}

.dv-header-top {
	padding: 16px 0;
	border-bottom: 1px solid #efeeee;
}

.dv-header-middle {
	padding: 16px 0;
}

.button-engager {
	display: inline-block;
	float: right;
}

.button-engager a {
	margin-left: 25px;
}


.list-group a[href="https://instanceshop.ru/family-look/"], .list-group a[href="https://instanceshop.ru/muzhskaya-odezhda/"] {
	font-weight: bold;
}


.dv-header-links a {
	font-size: 15px;
	padding-left: 12px;
	position: relative;
	color: #868686;
}

.dv-header-links a:first-child {
	margin-right: 20px;
}


.dv-header-links a:before {
	position: absolute;
	width: 4px;
	height: 4px;
	background: #868686;
	left: 0;
	top: calc(50% - 2px);
	content: " ";
	border-radius: 100%;
}

.dv-header-icons a {
	margin-right: 40px;
}

.dv-header-icons a:last-child {
	margin-right: 0;
}


.banner-pro .slick-prev {
	left: 35px;
	top: calc(50% - 10px);
	z-index: 99;
	opacity: 0.95;
}

.banner-pro .slick-next {
	right: 35px;
	top: calc(50% - 10px);
	z-index: 99;
	opacity: 0.95;
}


.product-grid .product-thumb .image {
	border: 2px solid #e7e7e7;
}

.product-thumb a {
	color: #000000;
	font-weight: 600;
}

#product-category .product-grid {
	padding-left: 8px;
	padding-right: 8px;
}

.dv-preview-section {
	font-size: 0;
	margin-top: 6px;
}

.dv-preview-section > * {
	font-size: 17px;
	vertical-align: top;
}


.product-grid .dv-product-title {
	display: inline-block;
	width: 75%;
	font-size: 15px;
	font-weight: 600;
}

.dv-preview-like {
	background-image: url(/image/category/preview-like.png);
	width: 28px;
	height: 28px;
	display: inline-block;
	float: right;
	margin-left: 10px;
	cursor: pointer;
}

.dv-preview-exchange {
	width: 18px;
	height: 18px;
	display: inline-block;
	background-image: url(/image/category/exchange.png);
	border: 0;
	background-color: inherit;
	float: right;
	margin-top: 4px;
	cursor: pointer;
}


.banner-pro {
	background: #e6e6e6;
	text-align: center;
}


/* FIN */


.dv-advantage-container {
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	flex-wrap: wrap;
	.dv-advantage-block{
		display: flex;
		align-items: center;
	}
}

.dv-advantage-container > div {
	width: 25%;
	font-size: 0;
}


.dv-advantage-image-container {
	width: 68px;
	display: inline-block;
	vertical-align: top;
}


.dv-advantage-text-container {
	width: calc(100% - 68px);
	display: inline-block;
	vertical-align: top;
	color: #8c8c8c;
	font-size: 15px;
	padding: 0px 6px 0 10px;

}
/* Блок на главной Family look*/
.dv-index-catalog-container {
	display: flex;
	flex-wrap: wrap;
	/*justify-content: space-between;*/
	justify-content: center;
	> div {
		width: 49%;
		position: relative;
		margin-bottom: 20px;
	}
	div:nth-child(even){
		margin-left: 20px;
	}
	div:last-child{
		display: none;
	}
}


.dv-index-catalog-block img {
	width: 100%;
}

.dv-index-catalog-block a {
	transition: .8s;
	color: white;
	border: 2px solid white;
	position: absolute;
	bottom: 0;
	left: 0;
	&:hover{
		background: #f7f7f7;
		color: #060102;
	}
}


.dv-index-catalog-block a {
	color: white;
	border: 3px solid white;
	position: absolute;
	top: 155px;
	left: 40px;
	height: 48px;
	line-height: 41px;
	width: 180px;
	text-align: center;
	box-sizing: border-box;
}

.dv-index-catalog-block:nth-child(3) a, .dv-index-catalog-block:nth-child(4) a {
	left: inherit;
	right: 60px;
}

.dv-main-sale-banner {
	position: relative;
}

.dv-main-sale-banner .dv-main-sale-banner-button {
	position: absolute;
	width: 280px;
	height: 48px;
	line-height: 48px;
	background-color: #1b1617;
	color: #ffffff;
	bottom: 46px;
	left: 40px;
	text-align: center;
	cursor: pointer;
}

.tab-content {
	margin-bottom: 45px;
	margin-top: 35px;
	font-size: 15px;
}


.nav-tabs {
	margin-bottom: 15px;
	border-bottom: 4px solid #e7e7e7;
	margin-top: 25px;
}

.nav-tabs .active {
	border-bottom: 4px solid #fe3266;
	margin-bottom: -4px;
}


.nav > li > a {
	padding: 20px 20px;
}


.nav-tabs > li {
	float: left;
	width: 33.33%;
	text-align: center;
	font-size: 16px;
}

.nav > li > a {
	padding: 20px 70px;
}

.dv-thumbnail {
	border: 3px solid #e7e7e7;
	border-radius: 0;
	min-height: 412px;
	vertical-align: middle;
	display: flex;
}

.dv-product-button {
	color: #444;
	font-weight: 500;
	cursor: pointer;
}


.dv-custom-delivery {
	position: relative;
	padding-left: 50px;
}


.dv-advanaced-product-container {
	margin-top: 16px;
}


.dv-custom-delivery:before {
	content: " ";
	position: absolute;
	left: 0;
	top: 6px;
	width: 38px;
	height: 28px;
	background-image: url(/image/product/4.png);
}

.dv-custom-share {
	position: relative;
	padding-left: 50px;
	margin-top: 20px;
	cursor: pointer;
}

.dv-custom-share:before {
	content: " ";
	position: absolute;
	left: 0;
	top: -9px;
	width: 38px;
	height: 38px;
	background-image: url(/image/product/5.png);
}

.input-quantity-container {
	margin: 14px 0 12px 0;
}

#product hr {
	margin-top: 9px;
	margin-bottom: 9px;
}


.dv-share-container {
	margin-top: 25px;
	display: none;
}


.price-popup-container {
	font-size: 36px;
	font-family: 'Myriad Pro';
}


select.size-select {
	border-radius: 0;
	height: 50px;
	border: 2px solid #2a2927;
	width: 70%;
}

.price-popup-container {
	font-size: 14px;
}

.category-inner-pro {
	color: #868686 !important;
}

.dv-price-link {
	padding-left: 8px;
}

.dv-price-link span {
	border-bottom: 2px dashed #444;
	font-size: 16px;
	color: #444;
	padding-bottom: 1px;
}

.slick-dots li button:before {
	font-size: 10px !important;
}


@media (min-width: 768px) {
	#menu .dropdown:hover .dropdown-menu {
		display: block;
		width: 100%;
	}

	.dv-custom-menu-container {
		min-width: 220px;
	}


}


.dv-custom-menu-new .container {
	display: flex;
}

#menu .dv-custom-menu-container ul {

	padding-left: 0;
	margin-left: 10px;
	display: block;
	list-style: none;

}


#menu .dv-custom-menu-container ul li a {
	color: #868686 !important;
	position: relative;
}

#menu .dv-custom-menu-container ul li a:before {
	content: "- ";
}

/* zzz */

.slider-pro {
	padding: 30px 0;
}


#product-product .slider-pro {
	padding: 15px 0 30px 0;
}

.dv-main-sale-banner img {
	width: 100%;
}


#product-product .slick-next {
	right: 15px;
	top: -18px;
}

#product-product .slick-prev {
	left: inherit;
	right: 65px;
	top: -18px;
}

.article-product {
	color: #868686;
	font-size: 15px;
}


.title-pro {
	text-align: center;
	text-transform: uppercase;
	font-size: 40px;
	font-family: 'Times New Roman';
	color: #1b1b1b;
	font-weight: 400;
	background-image: url(/image/sarsar.png);
}

.title-pro span {
	padding: 0 50px;
	background-color: white;
}


.product-grid .button-group, .product-thumb .button-group {
	position: absolute;
	top: 100px;
	width: auto;
	left: calc(50% - 65px);
	display: none;

}


.product-thumb .button-group button {
	width: 130px;
}


.product-grid:hover .button-group, .product-thumb:hover .button-group {
	display: block;
}

#product-product #content {
	margin-bottom: 50px;
}


@media (max-width: 1920px) {
	.banner-pro img {
		width: 100%;
	}
}

@media (max-width: 1199px) {

	.dv-footer-about-title br {
		display: none;
		font-size: 30px;
		line-height: 35px;
		margin: 20px 0 15px 0;
	}

	.dv-footer-extra-about a, .dv-footer-extra-about span {
		margin-right: 20px;
	}

	.dv-footer-about-title {
		font-size: 26px;
		line-height: 41px;
		margin: 20px 0 15px 0;
	}

	.dv-footer-cols .dv-contact-col {
		width: 100%;
	}

	.dv-footer-cols .dv-menu-col {
		width: 33.33%;
		padding-left: 10px;
	}

	.dv-footer-cols {
		display: flex;
		flex-wrap: wrap;
	}

	.dv-footer-phone a:last-child {
		float: none;
		margin-left: 30px;
	}


	#menu .nav > li > a {
		font-size: 14px;
	}

	.another-description-container .h2 {
		font-size: 24px;
	}

	.callme-button:before {
		content: none;
	}

	.callme-button {
		font-size: 14px;
		padding-right: 10px;
		padding: 13px 10px 10px 10px;
	}

	.product-thumb .price-old {
		font-size: 22px;
		display: block;
		margin-left: 0;
		margin-top: 10px;
	}
}

@media (max-width: 991px) {

	.product-h1 {
		display: none;
	}

	.title-hider {
		display: block;
	}


	.dv-index-catalog-block:nth-child(3) a, .dv-index-catalog-block:nth-child(4) a {
		right: 30px;
	}

	header {
		background: #f7f7f7;
	}

	.dv-header-phones {
		text-align: center;
	}

	.dv-header-phones a {
		display: block;
	}

	.dv-header-phones a:last-child {
		float: none;
		margin-top: 10px;
	}

	.dv-index-catalog-block a {
		top: 110px;
		left: 20px;
	}

	.dv-main-sale-banner .dv-main-sale-banner-button {
		bottom: 12px;
		left: 26px;
	}

	.dv-advantage-container > div {
		width: 50%;
		margin-bottom: 22px;
	}


	.dv-footer-about-image {
		width: 290px;
	}

	.dv-footer-about-text {
		width: calc(100% - 290px);
		padding-left: 40px;
	}

	.dv-footer-about-title {
	
	}


	.dv-footer-extra-about a, .dv-footer-extra-about span {
		margin-right: 0;
		display: block;
		margin-bottom: 14px;
	}

	.about-engage-wrapper {
		background-color: #f7f7f7;
		padding: 30px 0;
	}


	.product-thumb .price-old {
		font-size: 18px;
		display: inline-block;
		margin-left: 6px;
		margin-top: 0;
	}


	#product-product #column-left {
		display: none;
	}

}


@media (max-width: 767px) {

	.nav > li > a {
		padding: 20px 0px;
	}

	.menu_with_arrow:after {
		content: none;
	}

	.dv-header-icons {
		display: none;
	}

	.dv-header-top .container > div {
		width: 50%;
	}

	.dv-header-phones {
		text-align: left;
		padding-left: 40px;
	}

	.dv-header-logo img {
		width: 100%;
	}


}

@media (max-width: 730px) {
	.dv-header-links {
		display: none !important;
	}

	.dv-header-middle .container > div {
		width: 50% !important;
	}
}


@media (max-width: 650px) {


	#product-product .slider-pro {
		padding: 15px 0 0px 0;
	}


	.dv-footer-about {
		flex-wrap: wrap;
	}

	.dv-footer-about-image img {
		width: 40%;
	}

	.dv-footer-about-image {
		width: 100%;
		text-align: center;
		margin-bottom: 20px;
	}

	.dv-footer-about-text {
		width: 100%;
		padding-left: 0;
	}


	.dv-index-catalog-container > div {
		width: 100%;
	}

	.dv-index-catalog-block a {
		top: 60%;
		left: 40px;
	}

	.dv-index-catalog-block:nth-child(3) a, .dv-index-catalog-block:nth-child(4) a {
		right: 55px;
	}

	.dv-main-sale-banner .dv-main-sale-banner-button {
		width: 190px;
		height: 30px;
		line-height: 30px;
	}

}


@media (max-width: 599px) {

	.dv-footer-cols .dv-menu-col {
		width: 100%;
	}

	.dv-menu-title {
		width: 100%;
		margin-bottom: 18px;
	}

	ul.breadcrumb {
		display: none !important;
	}

	.d-header-mobile-container {
		display: block;
		text-align: center;
	}

	.d-header-mobile-container img {
		width: 90%;
	}


	.texthide {
		display: none;
	}

	.d-cart-container #cart button {
		padding: 13px 15px 10px 15px;
		width: auto;
	}


	.link-engager {
		bottom: 10%;
		width: 105px;
		height: 25px;
		line-height: 25px;
		font-size: 14px;
	}


}

@media (max-width: 515px) {

	.title-pro {
		background: none;
	}

	.title-pro span {
		padding: 0;
	}

	.dv-main-sale-banner {
		display: none;
	}

	.dv-header-phones {
		padding-left: 0;
	}


	.dv-footer-phone a {
		display: block;
		margin: 0 0 15px !important;
	}

	.d-header-mobile-container .d-header-phones a {
		display: block;
		margin-bottom: 10px;
	}
}

@media (max-width: 450px) {
	.button-engager a {
		margin-left: 8px;
	}

	.dv-advantage-container > div {
		width: 100%;
	}

	.dv-advantage-text-container {
		padding: 15px 6px 0 10px;
	}

}

@media (max-width: 400px) {
	.dv-header-phones a {
		font-size: 16px;
	}

	.nav-tabs > li {
		float: none;
		width: 100%;
		text-align: center;
	}
}


.supbutton {
	display: flex;
	justify-content: space-between;
	width: 62%;
	@include respond-down(md){
		width: 50%;
	}
	margin-top: 15px;
	> div {
		width: 33.33%;
		>a{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			align-content: center;
			text-align: center;
		}
		img {
			display: block;
			margin-left: 13px;
			margin-bottom: 5px;
		}
		&:last-child {
			width: 50%;
		}
	}
}

.list-group a.active {
	color: #ff3366;
}

.table {
	text-align: center;
	font-size: 0.9em;

}


.size-table td {
	border: 1px solid #999;
	padding: 5px;
	text-align: center;
}

.position-relative {
	position: relative;
}

.icon-new-products {
	position: absolute;
	background: #75ca75;
	padding: 5px 10px;
	top: 0;
	left: 0;
	color: #fff;
	font-weight: bold;
}

.icon-new-sale {
	position: absolute;
	background: #e21a1a;
	padding: 5px 10px;
	top: 0;
	left: 0;
	color: #fff;
	font-weight: bold;
}

.newsletter_module {
	margin-bottom: 20px;
}

#common-home h3 {
	text-align: center;
}

#common-home .newsletter_module {
	max-width: 500px;
	margin: 0 auto;
}
.uk-form-danger{
	border: 1px solid #f0506e;
}
