@import "variables";
@import "functions";
@import "mixins";
@import "media";
/*@tailwind base;*/
/*@tailwind components;*/

/* if you want to add wikit to the project https://uikit.com/ uncomment the lines below */
@import "node_modules/uikit/src/scss/variables";
@import "node_modules/uikit/src/scss/uikit";

@import "stylesheet";
@import "custom";

@import "h1";

@tailwind utilities;
