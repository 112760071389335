h1, .h1 {
	font-size: 28px;
	margin: 0 0 20px 15px;
	font-family: 'Times New Roman';
}
h2, .h2 {
	font-size: 24px;
	margin: 0 0 15px 10px;
}

h3, .h3{
	font-size: 22px;
	margin: 0 0 15px 10px;
}

h4, .h4{
	font-size: 20px;
	margin: 0 0 15px 10px;
}
